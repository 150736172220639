import React from 'react'

const LoginCard = ({ children }) => {
    return (
        <div className='login-container '>
            <div className='login-card fading-bottom'>
                {children}
            </div>
        </div>
    )
}

export default LoginCard