import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router'
import axios from 'axios';
// Material
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
// Components
import LoginCard from './components/LoginCard'

const NewPass = ({ mobile, authorized, tablet }) => {

    const { vkey } = useParams()
    const navigate = useNavigate();
    useEffect(() => {
        if (authorized) {
            // navigate("/");
        }
    }, [navigate, authorized])

    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")

    const [validatorError, setValidatorError] = useState("")
    const [success, setSuccess] = useState("")

    const sendLink = () => {
        if(password.length < 6 || password.length > 16){
            setValidatorError("Lösenord måste vara mellan 6 och 16 tecken")
            return
        }
        if(password !== rePassword){
            setValidatorError("lösenord matchar inte varandra")
            return
        }
        
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/repassword/api-change-admin-password.php`, {
            params: {
                password:password,
                vkey: vkey,
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            if (response.data === 5 || response.data === '5') {
                navigate("/");
            } else {
                setValidatorError("")
                setSuccess("Din lösen ord har bytat")
            }
        })
    }

    const toLogin = () => {
        navigate("/login")
    }

    return (
        <div style={{ display: 'flex' }}>
            <LoginCard>
                {success ? <div><Alert severity="success" style={{ marginBottom: 16 }}>{success}</Alert> <Button variant="contained"  fullWidth onClick={toLogin}>login</Button></div> :
                    <div>
                        <h1>Återlösenord</h1>
                        {validatorError ? <Alert severity="error" style={{ marginBottom: 16 }}>{validatorError}</Alert> : <></>}
                        <TextField label="Lösenord *" type="password" fullWidth style={{ marginBottom: 16 }} variant="outlined" onChange={(e) => setPassword(e.target.value)} />
                        <TextField label="repetera lösenord *" type="password" fullWidth style={{ marginBottom: 16 }} variant="outlined" onChange={(e) => setRePassword(e.target.value)} />
                        <Button variant="contained"  fullWidth onClick={sendLink}>Skicka länken</Button>
                        <p className="copy-right-text">Copyright © {new Date().getFullYear()} Used Truck SRL. All rights reserved.</p>
                    </div>
                }
            </LoginCard>
        </div>
    )
}

export default NewPass