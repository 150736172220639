import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import validator from 'validator';
// Material
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
// Components
import LoginCard from './components/LoginCard'

const ResetPass = ({ mobile, tablet }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [validatorError, setValidatorError] = useState("")
    const [success, setSuccess] = useState("")

    const sendLink = () => {
        if (!validator.isEmail(email)) {
            setValidatorError("E-post är fel")
            return
        }
        setLoading(true)
        axios.get(`${process.env.REACT_APP_SERVER_LINK}/api/repassword/api-send-link.php`, {
            params: {
                email: email,
            },
            headers: {
                'Authorization': `${process.env.REACT_APP_ACCESS_TOKEN}`
            }
        }).then((response) => {
            console.log(response)
            if (response.data === 5 || response.data === '5') {
                setValidatorError("Den e-post finns inte på databasen")
                setLoading(false)
            } else {
                setValidatorError("")
                setSuccess("Vi har skickat en länk till din e-post")
            }
        })
    }
    return (
        <div style={{ display: 'flex' }}>
            <LoginCard>
                {success ? <Alert severity="success">{success}</Alert> :
                    <div>
                        <h1>Glömt Lösenord?</h1>
                        {validatorError ? <Alert severity="error" style={{ marginBottom: 16 }}>{validatorError}</Alert> : <></>}
                        <TextField label="E-post *" fullWidth style={{ marginBottom: 16 }} variant="outlined" onChange={(e) => setEmail(e.target.value)} />
                        <LoadingButton
                            onClick={sendLink}
                            loading={loading}
                            variant="contained"
                            fullWidth
                            style={{ marginBottom: 16 }}
                        >
                            Skicka länken
                        </LoadingButton>
                        <div className='right-button-container mb-16'>
                            <p>Tillbaka till <Link to="/login">logga in</Link></p>
                            <div></div>
                        </div>
                        <p className="copy-right-text">Copyright © {new Date().getFullYear()} Used Truck SRL. All rights reserved.</p>
                    </div>
                }
            </LoginCard>
        </div>
    )
}

export default ResetPass